<template>
  <div class="procedure-group-item" :class="item.changed ? 'selected' : ''">
    <div class="columns">
      <div class="column col-auto">
        {{ idx + 1 }}
      </div>
      <div class="column">
        <span class="hover-link" @click="openDetail">{{ item.expense.name }}</span>
        <small class="ml-1 text-dark">({{ item.code | tuss }})</small>
      </div>
      <div class="column col-3">{{ customName(item.insurance) }}</div>
      <div class="column col-2 text-right">
        <span v-if="item.definition.transferType === 'value'">
          {{ item.definition.transferValue | currency }}
        </span>
        <span v-else>{{ item.definition.transferValue | number }}%</span>
      </div>
    </div>
    <template v-if="detail.show">
      <div class="loading loading-lg mt-2" v-if="detail.loading" />
      <div v-else>
        <div class="divider" />
        <div class="columns">
          <div class="column col-6 col-md-12 col-sm-12 form-group">
            <label class="form-label">Regra de atualização</label>
            <select id="accredited" class="form-select"
                    v-model="detail.form.definition.custom">
              <option :value="false">Atualizar automaticamente</option>
              <option :value="true">Não atualizar automaticamente</option>
            </select>
          </div>
          <div class="column col-6 col-md-12 col-sm-12 form-group">
            <label class="form-label">
              Recebimento de honorário
              <span class="c-hand tooltip tooltip-left"
                    data-tooltip="Quando PF não deduz imposto do convênio">
                    <fa-icon
                      class="text-info"
                      :icon="['fal', 'info-circle']"
                    />
                  </span>
            </label>
            <select id="accredited" class="form-select"
                    v-model="detail.form.definition.accredited"
                    :disabled="!isCustom" @change="changedAccreditation">
              <option value="pf">Recebido pelo profissional [PF]</option>
              <option value="pj">Recebido pela clínica/hospital [PJ]</option>
            </select>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Valor convênio</label>
            <dx-input-number
              class="form-input"
              v-model=detail.form.definition.insuranceValue
              :disabled="!isCustom || hasFee"
              :precision="2"
            />
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Valor paciente</label>
            <dx-input-number
              class="form-input"
              v-model=detail.form.definition.particularValue
              :disabled="!isCustom"
              :precision="2"
            />
          </div>
          <div class="column col-3 col-md-3 col-sm-12 form-group"
               :class="{'has-error': $v.detail.form.definition.transferValue.$error}">
            <label class="form-label">Valor repasse</label>
            <div class="input-group">
              <dx-input-number class="form-input"
                               v-model="detail.form.definition.transferValue"
                               :precision="2" maxlength="7"
                               :disabled="!isCustom"
                               @blur="$v.detail.form.definition.transferValue.$touch()"
              />
              <select class="form-select"
                      v-model="detail.form.definition.transferType"
                      :disabled="!isCustom"
                      style="max-width: 2.5rem; background-color: #f8f9fa">
                <option value="percent">%</option>
                <option value="value">R$</option>
              </select>
            </div>
          </div>
          <div class="column col-3 col-md-3 col-sm-12 form-group">
            <label class="form-label">Outras taxas (%)</label>
            <div class="input-group">
              <dx-input-number class="form-input"
                               v-model="detail.form.definition.taxValue"
                               :precision="2" maxlength="7"
                               :disabled="!isCustom"
              />
              <select class="form-select"
                      v-model="detail.form.definition.taxType"
                      :disabled="!isCustom"
                      v-if="detail.form.definition.accredited === 'pf'"
                      style="max-width: 2.5rem; background-color: #f8f9fa">
                <option value="pf">PF</option>
                <option value="pj">PJ</option>
              </select>
            </div>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Custo operacional</label>
            <dx-input-number
              class="form-input"
              v-model=detail.form.definition.operationalCostValue
              :disabled="!isCustom"
              :precision="2"
              @change="calcInsuranceValueWithFee"
            />
          </div>
<!--          <div class="column col-2 col-md-12 col-sm-12 form-group">-->
<!--            <label class="form-label">Valor de CH</label>-->
<!--            <dx-input-number-->
<!--              class="form-input"-->
<!--              v-model=detail.form.definition.chValue-->
<!--              :disabled="!isCustom"-->
<!--              :precision="2"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="column col-2 col-md-12 col-sm-12 form-group">-->
<!--            <label class="form-label">Qtde. de CH</label>-->
<!--            <dx-input-number-->
<!--              class="form-input"-->
<!--              v-model=detail.form.definition.chQuantity-->
<!--              :disabled="!isCustom"-->
<!--              :precision="2"-->
<!--            />-->
<!--          </div>-->
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Valor m² do filme</label>
            <dx-input-number
              class="form-input"
              v-model=detail.form.definition.filmValue
              :disabled="!isCustom"
              :precision="2"
              @change="calcInsuranceValueWithFee"
            />
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Qtde. filme</label>
            <dx-input-number
              class="form-input"
              v-model=detail.form.definition.filmQuantity
              :disabled="!isCustom"
              :precision="2"
              @change="calcInsuranceValueWithFee"
            />
          </div>
          <div class="column col-3 col-md-3 col-sm-12 form-group">
            <label class="form-label">Valor honorário</label>
            <dx-input-number
              class="form-input"
              v-model=detail.form.definition.feeValue
              :disabled="!isCustom"
              @change="calcInsuranceValueWithFee"
              :precision="2"
            />
          </div>
        </div>
        <div class="divider" />
        <span class="text-bold">Repassar ao profissional os custos e acréscimos de:</span>
        <div class="columns">
          <div class="column col-4 form-group">
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.operationalCost"
                     :disabled="!isCustom" />
              <i class="form-icon"/>Custo operacional
            </label>
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.film"
                     :disabled="!isCustom" />
              <i class="form-icon"/>Filme
            </label>
          </div>
          <div class="column col-4 form-group">
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.interest"
                     :disabled="!isCustom" />
              <i class="form-icon"/>Juros
            </label>
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.discount"
                     :disabled="!isCustom" />
              <i class="form-icon"/>Desconto
            </label>
          </div>
          <div class="column col-4 form-group">
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.rate"
                     :disabled="!isCustom" />
              <i class="form-icon"/>Taxa do cartão
            </label>
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.insuranceTax"
                     :disabled="!isCustom || detail.form.definition.accredited === 'pf'"/>
              <i class="form-icon"/>Aplicar imposto do plano
            </label>
          </div>
        </div>
        <div class="divider" />
        <div class="columns">
          <div class="column col-6 col-md-12 col-sm-12 form-group">
            <label class="form-switch">
              <input type="checkbox"
                     v-model="detail.form.definition.accreditation.enabled"
                     :disabled="!isCustom" />
              <i class="form-icon"/>Utilizar o credenciamento de outro profissional
            </label>
          </div>
          <div class="column col-6 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.detail.form.definition
               .accreditation.professional.key.$error}">
            <select id="accredited" class="form-select"
                    v-model="detail.form.definition.accreditation.professional.key"
                    :disabled="!isCustom || !detail.form.definition.accreditation.enabled">
              <option value="">[Selecione um profissional]</option>
              <option v-for="item in professionals" :value="item.key" :key="item.key">
                {{ item.name }}
                <template v-if="item.specialty">({{ item.specialty.name }})</template>
              </option>
            </select>
          </div>
        </div>
        <div class="text-right mt-2">
          <button class="btn btn-gray btn-icon btn-icon-left mr-1"
                  @click="detail.show = false">
            <fa-icon :icon="['fal', 'times']" />Fechar
          </button>
          <button class="btn btn-primary btn-icon btn-icon-left"
                  :class="{loading: detail.saving}"
                  :disabled="detail.saving"
                  @click="save">
            <fa-icon :icon="['fal', 'save']" />Salvar
          </button>
        </div>
        <template v-if="!isCustom">
          <div class="divider" />
          <strong class="text-info mt-2" >
            * Campos inativados. Regras e valores definidos automaticamente no convênio.<br>
            <small class="text-warning">
              Para criar uma regra personalizada, altere o campo Regra de autorização para:
              <strong>Não atualizar automaticamente</strong>
            </small>
          </strong>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from 'src/helpers/object';
import { customName } from 'src/helpers/insurance';

export default {
  mixins: [formMixin],
  props: {
    professionalId: {
      type: String,
    },
    idx: {
      type: Number,
    },
    data: {
      type: Object,
    },
    professionals: {
      type: Array,
    },
  },
  mounted() {
    this.item = mergeFrom(this.blankItem(), this.data);
  },
  data() {
    return {
      item: this.blankItem(),
      detail: {
        show: false,
        loading: false,
        saving: false,
        form: this.blankFormDetail(),
      },
      customName,
    };
  },
  computed: {
    isCustom() {
      return this.detail.form.definition.custom;
    },
    hasFee() {
      return this.detail.form.definition.feeValue > 0
        || this.detail.form.definition.operationalCostValue > 0
        || this.detail.form.definition.filmValue > 0;
    },
  },
  validations() {
    const rules = {
      detail: {
        form: {
          definition: {
            accreditation: {
              professional: {
                key: {},
              },
            },
            accredited: { required },
            insuranceValue: { required },
            particularValue: { required },
            transferValue: { required },
          },
        },
      },
    };

    if (this.detail.form.definition.accreditation.enabled) {
      rules.detail.form.definition.accreditation.professional.key = { required };
    }

    return rules;
  },
  methods: {
    load() {
      this.detail.loading = true;
      return this.$http
        .get(`professionals/${this.professionalId}/transfer-values/${this.item.id}`)
        .then(({ data }) => {
          this.detail.form = mergeFrom(this.blankFormDetail(), data);
          if (data.definition.accreditation.enabled) {
            const { professional } = data.definition.accreditation;
            this.detail.form.definition.accreditation
              .professional.key = `${professional.id}-${professional.specialty.code}`;
          }
        })
        .catch(() => {})
        .finally(() => {
          this.detail.loading = false;
        });
    },
    async openDetail() {
      if (!this.detail.show) {
        this.load();
      }
      this.$v.detail.form.$reset();
      this.detail.show = !this.detail.show;
    },
    changedAccreditation() {
      this.detail.form.definition.taxType = this.detail.form.definition.accredited;
    },
    calcInsuranceValueWithFee() {
      if (!this.hasFee) {
        return;
      }
      const filmValue = this.detail.form.definition.filmValue || 0;
      const filmQuantity = this.detail.form.definition.filmQuantity || 1;
      const ucoValue = this.detail.form.definition.operationalCostValue || 0;
      const feeValue = this.detail.form.definition.feeValue || 0;
      this.detail.form.definition.insuranceValue = (filmValue * filmQuantity) + ucoValue + feeValue;
    },
    save() {
      this.$v.detail.form.$touch();
      if (this.$v.detail.form.$error || this.detail.saving) {
        return null;
      }

      this.detail.saving = true;

      const data = this.clone(this.detail.form);

      if (this.detail.form.definition.accreditation.enabled) {
        const foundProfessional = this.professionals
          .find(({ key }) => key === this.detail.form.definition.accreditation.professional.key);
        data.definition.accreditation.professional = {
          _id: foundProfessional.id,
          name: foundProfessional.name,
          council: foundProfessional.council,
          specialty: foundProfessional.specialty,
        };
      } else {
        data.definition.accreditation.professional = null;
      }

      return this.$http
        .put(`/professionals/${this.professionalId}/transfer-values/${this.item.id}`, data)
        .then(() => {
          this.$toast.show('Salvo com sucesso!');
          this.detail.show = false;
          this.item.definition.transferType = data.definition.transferType;
          this.item.definition.transferValue = data.definition.transferValue;
        })
        .catch(() => {
          this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
        })
        .finally(() => {
          this.detail.saving = false;
        });
    },
    blankItem() {
      return {
        id: '',
        code: '',
        expense: {
          id: '',
          name: '',
          type: '',
          modality: '',
          specialty: {
            code: '',
            name: '',
          },
        },
        insurance: {
          id: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        definition: {
          insuranceValue: '',
          particularValue: '',
          transferType: '',
          transferValue: '',
        },
        hasPreparation: false,
        hasProfessionalCustom: false,
      };
    },
    blankFormDetail() {
      return {
        id: '',
        expense: {
          id: '',
          type: '',
          name: '',
          billAs: {
            id: '',
            name: '',
          },
        },
        insurance: {
          id: '',
          type: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        definition: {
          accreditation: {
            enabled: false,
            professional: {
              key: '',
              id: '',
              name: '',
              specialty: {
                code: '',
                name: '',
              },
            },
          },
          accredited: 'pj',
          chValue: '',
          chQuantity: '',
          operationalCostValue: '',
          filmValue: '',
          filmQuantity: '',
          feeValue: '',
          insuranceValue: '',
          particularValue: '',
          taxValue: '',
          taxType: 'pj',
          transferValue: '',
          transferType: '',
          postageCode: '',
          reportValue: '',
          tax: true,
          rate: true,
          discount: true,
          interest: true,
          operationalCost: true,
          film: true,
          insuranceTax: true,
          custom: false,
        },
      };
    },
  },
};
</script>

<style lang="scss">
@import '../../../assets/scss/variables';

.procedure-group-item {
  &.selected {
    background-color: $gray-color-ultra-light;
  }
  .hover-link {
    font-size: $font-size-sm;
    font-weight: bold;
  }
  .divider {
    border-top: $border-width-lg solid $light-color;
  }
}
</style>
