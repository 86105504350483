<template>
  <div class="page-container professionals-page">
    <portal to="page-name">Profissionais</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Profissionais</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column form-group">
            <div class="input-group">
              <input type="text" id="filter-search" name="filter-search" class="form-input"
                     v-model="filter.search" maxlength="100" autocomplete="nope" v-focus>
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto">
            <button class="btn btn-primary btn-icon btn-icon-left mr-2"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/> Novo profissional
            </button>
            <button class="btn btn-gray btn-icon btn-icon-left"
                    :class="{loading: printing}" :disabled="printing"
                    @click="print">
              <fa-icon :icon="['fal', 'print']"/>Imprimir
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading"></div>
        <template v-else>
          <template v-if="data.items.length === 0">
            <div class="empty mt-2">
              <div class="empty-icon">
                <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
              </div>
              <p class="empty-title h5">Profissionais</p>
              <p class="empty-subtitle">
                Nenhum profissional encontrado. Verifique os filtros para realizar novas buscas
              </p>
            </div>
          </template>
          <template v-else>
            <table class="table table-striped table-hover">
              <thead>
              <tr>
                <th class="hide-sm" style="width: 40px">#</th>
                <th>Nome</th>
                <th class="hide-sm hide-md">CPF</th>
                <th class="hide-sm hide-md">Conselho</th>
                <th>Especialidade</th>
                <th style="width: 70px" />
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in data.items" :key="i">
                <td class="hide-sm">{{ i + 1 }}</td>
                <td><router-link :to="editLink(item)">{{ item.name }}</router-link></td>
                <td class="hide-sm hide-md">
                  {{ item.identity ? item.identity.value : '' | cpf }}
                </td>
                <td class="hide-sm hide-md">
                  <span v-if="item.professional.councils
                  && item.professional.councils.length > 0">
                    {{ getCouncil(item.professional.councils[0]) }}
                  </span>
                </td>
                <td>
                  <span v-if="item.professional.specialtyNames.length">
                    {{ item.professional.specialtyNames[0] }}
                    <span
                      v-if="item.professional.specialtyNames.length > 1"
                      class="label text-primary tooltip hover-link ml-1 "
                      :data-tooltip="item.professional.specialtyNames.slice(1).join('\n')">
                      +{{ item.professional.specialtyNames.length -1 }}
                    </span>
                  </span>
                </td>
                <td class="text-right">
                  <button class="btn btn-sm btn-action btn-icon btn-secondary tooltip mr-1"
                          @click="edit(item)" data-tooltip="Editar">
                    <fa-icon :icon="['fal', 'pencil']"/>
                  </button>
                  <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                          @click="remove(item)" data-tooltip="Excluir">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE_PROFESSIONAL, PRINT_PROFESSIONAL_LIST } from '@/data/actions/modules/general';

export default {
  data() {
    return {
      path: '/professionals',
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      printing: false,
      filter: {
        search: '',
      },
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
    };
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  computed: {
    canAccessRemoveProfessional() {
      if (DELETE_PROFESSIONAL) {
        return this.$can(DELETE_PROFESSIONAL);
      }
      return true;
    },
    canAccessPrintProfessional() {
      if (PRINT_PROFESSIONAL_LIST) {
        return this.$can(PRINT_PROFESSIONAL_LIST);
      }
      return true;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          data.items = data.items.map((item) => {
            item.professional.specialtyNames = item.professional.specialties
              .map(({ name }) => name);
            return item;
          });
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$router.push(`/clinical${this.path}/create`);
    },
    edit(item) {
      this.$router.push(`/clinical${this.path}/${item.id}/edit`);
    },
    remove(item) {
      if (!this.canAccessRemoveProfessional) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          `<div class="text-center">
            <h5 class="text-center">Atenção!</h5>
            <div>Deseja realmente excluir este profissional?</div>
            <p>
              <small class="text-warning text-bold">
              O profissional será removido de todos os cadastros do sistema
              (procedimentos, agenda, impressos e etc...)
              </small>
            </p>
          </div>`,
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: close => this.$http
              .delete(`/professionals/${item.id}`)
              .then(() => {
                const idx = this.data.items.indexOf(item);
                this.data.items.splice(idx, 1);
              })
              .finally(() => {
                close();
              }),
          },
        ],
      });
    },
    editLink(item) {
      return `/clinical${this.path}/${item.id}/edit`;
    },
    print() {
      if (!this.canAccessPrintProfessional) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return false;
      }
      this.printing = true;
      return this.$file
        .print('/professionals/print')
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    getCouncil(item) {
      if (!item.name || !item.state || !item.record) {
        return '-';
      }
      return `${item.name}-${item.state} ${item.record}`;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .professionals-page {
    .btn-fixed-width {
      font-size: $font-size-lg;
      height: 2.2rem;
      margin: $layout-spacing-lg 0;
      width: 11rem;
    }
  }
</style>
