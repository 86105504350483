<template>
  <tr>
    <td style="width: 50px">
      <label class="form-checkbox">
        <input type="checkbox"
               v-model="item.selected">
        <i class="form-icon"></i>
      </label>
    </td>
    <td>
      <span v-if="type === 'plan'">{{ item.name }}</span>
      <span v-else>{{ item.insurance ? item.insurance.name : '' }}</span>
    </td>
    <td style="width: 200px">
      <input type="text"
             v-model="item.professionalCode"
             class="form-input"
             maxlength="20"
             placeholder="000000"
             v-mask-number
             :disabled="!item.selected"
      />
    </td>
    <td style="width: 120px">
      <input type="text"
             v-model="item.cnesCode"
             class="form-input"
             maxlength="7"
             placeholder="000000"
             v-mask-number
             :disabled="!item.selected"
      />
    </td>
    <td style="width: 80px">
      <button class="btn btn-icon btn-action mr-1" @click="cancel"
              :class="change ? 'btn-gray' : 'btn-neutral'"
              :disabled="!change">
        <fa-icon :icon="['fal', 'times']" />
      </button>
      <button class="btn btn-icon btn-action"
              :class="classes"
              :disabled="saving || !change"
              @click="save">
        <fa-icon :icon="['fal', 'save']" />
      </button>
    </td>
  </tr>
</template>

<script>
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    type: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      change: false,
      saving: false,
      item: this.blankItem,
    };
  },
  watch: {
    item: {
      handler() {
        this.changeValues();
      },
      deep: true,
    },
  },
  mounted() {
    this.item = this.data;
  },
  computed: {
    classes() {
      const items = [this.change ? 'btn-primary' : 'btn-neutral'];

      if (this.saving) {
        items.push('loading');
      }

      return items;
    },
  },
  methods: {
    changeValues() {
      const changedSelect = this.item.preChange.selected !== this.item.selected;
      const changedProfessionalCode = this.item.preChange.professionalCode
        !== this.item.professionalCode;
      const changedCnesCode = this.item.preChange.cnesCode
        !== this.item.cnesCode;
      this.change = changedSelect || changedProfessionalCode || changedCnesCode;
    },
    setInitialValues() {
      this.item.selected = this.item.preChange.selected;
      this.item.professionalCode = this.item.preChange.professionalCode;
      this.item.cnesCode = this.item.preChange.cnesCode;
    },
    cancel() {
      this.change = false;
      this.setInitialValues();
    },
    save() {
      if (this.saving) {
        return null;
      }

      this.saving = true;

      const data = this.clone(this.item);

      return this.$http
        .post('/professional-insurances', data)
        .then(() => {
          this.item.preChange.selected = data.selected;
          this.item.preChange.professionalCode = data.professionalCode;
          this.item.preChange.cnesCode = data.cnesCode;
          this.change = false;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    blankItem() {
      return {
        id: '',
        name: '',
        insurance: {
          id: '',
          type: '',
          name: '',
          plan: {
            id: '',
            name: '',
          },
        },
        preChange: {
          cnesCode: '',
          professionalCode: '',
          selected: false,
        },
        cnesCode: '',
        professionalCode: '',
        selected: false,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

.professional-insurance-item {
}
</style>
