import formMixin from 'src/mixins/form';
import states from 'src/data/states';
import { required, email } from 'vuelidate/src/validators';
import { phone, date } from 'src/data/validators';
import { mergeFrom } from 'src/helpers/object';
import {
  CPF as CPF_IDENTITY_TYPE,
  CNPJ as CNPJ_IDENTITY_TYPE,
} from '@/data/identity-types';
import {
  stringify as stringifyIdentity,
  sanitize as sanitizeIdentity,
} from '@/helpers/indentity';

export default {
  mixins: [formMixin],
  data() {
    return {
      type: '',
      isNew: false,
      includesUserData: false,
      loading: false,
      loadingAddress: false,
      saving: false,
      states,
      form: this.blankForm(),
      isIdentitySaved: false,
    };
  },
  mounted() {
    this.form = this.blankForm();
  },
  computed: {
    address: {
      get() {
        if (this.form.addresses.length === 0) {
          this.form.addresses.push(this.blankAddressForm());
        }
        return this.form.addresses[0];
      },
      set(value) {
        this.form.addresses[0] = value;
      },
    },
    canChangeIdentity() {
      return !this.form.id || !this.isIdentitySaved;
    },
  },
  methods: {
    entityValidationRules(extendFn = null) {
      const rules = {
        form: {
          name: { required },
          companyName: {},
          phone: { phone },
          cellphone: { phone },
          email: { email },
          alertAt: { date },
        },
      };
      if (this.form.type === 'company') {
        rules.form.companyName = { required };
      } else {
        rules.form.birthDate = { required, date };
        rules.form.gender = { required };
      }

      if (extendFn !== null) {
        return extendFn(rules);
      }

      return rules;
    },
    load(id, path) {
      this.loading = true;
      return this.$http
        .get(`${path}/${id}`, {
          params: {
            user: this.includesUserData,
          },
        })
        .then(({ data }) => {
          const identity = this.clone(this.form.identity, true);
          if (identity && !data.identity) {
            data.identity = identity;
          }
          this.form = this.dataFromApi(mergeFrom(this.blankForm(), data));
          if (this.form.identity && this.form.identity.value) {
            this.isIdentitySaved = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadEntityByIdentity(path) {
      if (!this.form.identity.value) return null;

      const identity = stringifyIdentity(this.form.identity);

      return this.$http
        .get(`/entities/${identity}`, {
          params: {
            user: this.includesUserData,
          },
        })
        .then(({ data }) => this.load(data.id, path))
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadEntity(params, path) {
      this.loading = true;
      return this.$http
        .get('/entities', params)
        .then(({ data }) => {
          if (data.items && data.items.length) {
            this.isNew = false;
            return this.load(data.items[0].id, path);
          }
          return data;
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save(path) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.saving = true;
      const entity = this.dataToApi(this.form);

      const request = this.isNew
        ? this.$http.post(path, entity)
        : this.$http.put(`${path}/${this.form.id}`, entity);

      // eslint-disable-next-line consistent-return
      return request
        .catch(e => Promise.reject(e))
        .then(({ data }) => {
          this.saving = false;
          if ('addresses' in data) {
            this.form.addresses = data.addresses;
          }
          if (this.form.identity && this.form.identity.value) {
            this.isIdentitySaved = true;
          }
          return data;
        });
    },
    dataToApi(data) {
      const localData = this.clone(data);
      localData.phone = this.onlyNumbers(localData.phone);
      localData.cellphone = this.onlyNumbers(localData.cellphone);

      if (localData.type === 'person') {
        if (localData.birthDate) {
          localData.birthDate = this.dateToApi(localData.birthDate);
        }
        if (!localData.profession || !localData.profession.code) {
          localData.profession = null;
        }
      }

      if (localData.identity && data.identity.value) {
        localData.identity = sanitizeIdentity(localData.identity);
      } else {
        delete localData.identity;
      }

      if (localData.secondaryIdentity && data.secondaryIdentity.value) {
        localData.secondaryIdentity = sanitizeIdentity(localData.secondaryIdentity);
      } else {
        delete localData.secondaryIdentity;
      }

      if (localData.addresses.length > 0) {
        // eslint-disable-next-line no-return-assign,no-param-reassign
        localData.addresses.forEach(item => item.postalCode = this.onlyNumbers(item.postalCode));
      }

      if ('patient' in localData) {
        if ('password' in localData.patient && localData.patient.password
          && localData.patient.password.trim().length > 0) {
          delete localData.patient.passwordConfirmation;
        }
      }

      if ('professional' in localData) {
        if ('council' in localData.professional) {
          localData.professional.councils = [localData.professional.council];
        }
        if ('password' in localData.professional && localData.professional.password
          && localData.professional.password.trim().length > 0) {
          delete localData.professional.passwordConfirmation;
        }
      }

      if ('collaborator' in localData && localData.collaborator.password
        && localData.collaborator.password.trim().length > 0) {
        delete localData.collaborator.passwordConfirmation;
      }

      if ('customer' in localData) {
        if ('user' in localData.customer) {
          localData.customer.user.cpf = this.onlyNumbers(localData.customer.user.cpf);
          localData.customer.user.birthDate = this.dateToApi(localData.customer.user.birthDate);
        }
        if ('plan' in localData.customer) {
          localData.customer.plan.trialExpiresAt = this.dateToApi(localData
            .customer.plan.trialExpiresAt);
          localData.customer.plan.unitValue = this.decimalToApi(localData.customer.plan.unitValue);
        }
        if ('payment' in localData.customer) {
          if (localData.customer.payment.method === 'bank_slip') {
            delete localData.customer.payment.creditCard;
          }
          localData.customer.payment.day = this.decimalToApi(localData.customer.payment.day);
        }
      }

      return localData;
    },
    dataFromApi(data) {
      const localData = this.clone(data);

      if (localData.identity === null) {
        localData.identity = this.blankForm().identity;
      }

      if (localData.secondaryIdentity === null) {
        localData.secondaryIdentity = this.blankForm().secondaryIdentity;
      }

      if (localData.type === 'person') {
        if (localData.birthDate) {
          localData.birthDate = this
            .dateFromApi(localData.birthDate, true);
        }
      }

      if ('professional' in localData) {
        localData.professional = Object.assign({},
          this.blankProfessionalForm().professional, localData.professional);
        if ('councils' in localData.professional
          && localData.professional.councils.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          localData.professional.council = localData.professional.councils[0];
        }
      }

      if ('customer' in localData) {
        if ('user' in localData.customer) {
          localData.customer.user.birthDate = this
            .dateFromApi(localData.customer.user.birthDate, true);
        }
        if ('plan' in localData.customer) {
          localData.customer.plan.trialExpiresAt = this.dateFromApi(localData
            .customer.plan.trialExpiresAt, true);
          localData.customer.plan.unitValue = localData.customer.plan.unitValue.toFixed(2);
          localData.customer.plan.totalValue = localData.customer.plan.totalValue.toFixed(2);
        }
      }

      if (this.includesUserData && data.user) {
        const group = Array.isArray(data.user.groups) && data.user.groups.length > 0
          ? data.user.groups[0]
          : '';

        if (this.type === 'professional') {
          localData.professional.permissionGroup = group;
        }
        if (this.type === 'collaborator') {
          localData.collaborator.permissionGroup = group;
        }
      }

      return localData;
    },
    loadAddress() {
      const postalCode = this.onlyNumbers(this.address.postalCode);
      if (postalCode && postalCode.length === 8) {
        this.loadingAddress = true;
        this.$cep.get(postalCode)
          .then((data) => {
            this.address.city = data.city;
            this.address.state = data.state;
            this.address.neighborhood = data.neighborhood;
            this.address.addressLine1 = data.street;
          })
          .catch(() => {})
          .then(() => {
            this.loadingAddress = false;
          });
      }
    },
    blankBaseForm() {
      return {
        id: '',
        identityId: '',
        type: 'person',
        name: '',
        socialName: '',
        birthDate: '',
        gender: '',
        civilStatus: '',
        profession: {
          code: '',
          name: '',
        },
        companyName: '',
        identity: {
          type: '',
          value: '',
        },
        secondaryIdentity: {
          type: '',
          value: '',
        },
        email: '',
        imageUrl: '',
        phone: '',
        cellphone: '',
        addresses: [],
        site: '',
        alert: '',
        alertAt: '',
        notes: '',
        user: {
          usernames: [],
          groups: [],
          actions: [],
        },
      };
    },
    blankAddressForm() {
      return {
        type: '',
        postalCode: '',
        state: '',
        city: '',
        neighborhood: '',
        addressLine1: '',
        addressLine2: '',
      };
    },
    blankBranchForm() {
      return {
        type: 'company',
        branch: {
          active: true,
          matrix: false,
          cnes: '',
        },
      };
    },
    blankInsuranceForm() {
      return {
        type: 'company',
        insurance: {
          active: true,
          type: '',
          record: '',
        },
      };
    },
    blankProfessionalForm() {
      return {
        professional: {
          active: true,
          permissionGroup: '',
          password: '',
          passwordConfirmation: '',
          council: {
            name: '',
            state: '',
            record: '',
          },
          about: '',
          title: '',
          branches: [],
          specialties: [],
          degreesParticipation: [],
          councils: [],
          insurances: [],
          experiences: '',
          diseases: [],
          school: '',
          signature: '',
          signatureImageUrl: null,
          partners: [],
        },
      };
    },
    blankPatientForm() {
      return {
        patient: {
          active: true,
          password: '',
          passwordConfirmation: '',
          profiles: [],
          cns: '',
          insurance: {
            planId: '',
            record: '',
            validity: '',
          },
          motherName: '',
          fatherName: '',
        },
      };
    },
    blankClientForm() {
      return {
        type: 'company',
        client: {
          active: true,
          coordinator: {
            id: null,
            name: '',
            council: {
              name: '',
              state: '',
              record: '',
            },
          },
        },
      };
    },
    blankCollaboratorForm() {
      return {
        collaborator: {
          active: true,
          permissionGroup: '',
          password: '',
          passwordConfirmation: '',
          useCallCenter: false,
          insurances: [],
        },
      };
    },
    blankPartnerForm() {
      return {
        type: 'company',
        partner: {
          active: true,
        },
      };
    },
    blankProviderForm() {
      return {
        type: 'company',
        provider: {
          active: true,
        },
      };
    },
    blankCustomerForm() {
      return {
        type: 'company',
        customer: {
          status: 'active',
          type: '',
          contractedAt: '',
          terminatedAt: '',
          user: {
            cpf: '',
            name: '',
            birthDate: '',
            gender: '',
            email: '',
            password: '',
            passwordConfirmation: '',
          },
          plan: {
            type: '',
            unitValue: '',
            totalValue: '',
            licences: '',
            trialExpiresAt: '',
          },
          payment: {
            method: '',
            day: '',
            periodicity: '',
            creditCard: {
              cardHolder: '',
              expiresAt: '',
              number: '',
              serializedToken: '',
            },
          },
        },
      };
    },
    blankForm() {
      let data;
      if (this.type === 'branch') {
        data = { ...this.blankBaseForm(), ...this.blankBranchForm() };
      } else if (this.type === 'collaborator') {
        data = { ...this.blankBaseForm(), ...this.blankCollaboratorForm() };
      } else if (this.type === 'customer') {
        data = { ...this.blankBaseForm(), ...this.blankCustomerForm() };
      } else if (this.type === 'insurance') {
        data = { ...this.blankBaseForm(), ...this.blankInsuranceForm() };
      } else if (this.type === 'patient') {
        data = { ...this.blankBaseForm(), ...this.blankPatientForm() };
      } else if (this.type === 'professional') {
        data = { ...this.blankBaseForm(), ...this.blankProfessionalForm() };
      } else if (this.type === 'partner') {
        data = { ...this.blankBaseForm(), ...this.blankPartnerForm() };
      } else if (this.type === 'provider') {
        data = { ...this.blankBaseForm(), ...this.blankProviderForm() };
      } else if (this.type === 'client') {
        data = { ...this.blankBaseForm(), ...this.blankClientForm() };
      } else {
        data = this.blankBaseForm();
      }

      if (data.type === 'company') {
        data.identity.type = CNPJ_IDENTITY_TYPE;
      } else {
        data.identity.type = CPF_IDENTITY_TYPE;
      }

      return data;
    },
  },
};
