import types, { CPF } from '@/data/identity-types';

export function sanitizeValue(type, value) {
  const identityType = types.find(item => item.type === type);
  return identityType && typeof identityType.sanitizer === 'function'
    ? identityType.sanitizer(value)
    : value;
}

export function sanitize(identity) {
  if (!identity || !identity.value) return null;
  return {
    type: identity.type,
    value: sanitizeValue(identity.type, identity.value),
  };
}

export function parse(value) {
  let identity;

  if (value) {
    if (typeof value === 'string') {
      let type = null;

      if (value.includes(':')) {
        [type, value] = value.split(':');
      }

      if (value) {
        identity = {
          type: type || CPF,
          value,
        };
      }
    } else {
      identity = value;
    }
  }

  return identity ? sanitize(identity) : null;
}

export function stringify(value) {
  if (!value) return '';
  const identity = sanitize(value);
  return [identity.type, identity.value].filter(v => !!v).join(':');
}
