<template>
  <div class="professional-procedures">
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="empty mt-2" v-if="items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <div class="empty-title h6">Procedimentos</div>
        <div class="empty-subtitle">
          <slot>
            Nenhum procedimento cadastrado.
          </slot>
        </div>
      </div>
      <template v-else>
        <div class="input-group">
          <input type="text" class="form-input" v-model="search"
                 placeholder="Filtrar...">
          <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                  tabindex="-1" v-if="!search.trim()">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
          <button class="btn btn-gray btn-action input-group-btn btn-icon"
                  tabindex="-1" v-else @click="search = ''">
            <fa-icon :icon="['fal', 'times']"/>
          </button>
        </div>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>
              <label class="form-checkbox">
                <input type="checkbox"
                       @change="selectAllItems"
                       :checked="getSelected"
                       :indeterminate.prop="getSelected === null">
                <i class="form-icon"></i>
              </label>
            </th>
            <th>Procedimento</th>
            <th style="width: 80px"></th>
          </tr>
          </thead>
          <tbody>
          <procedure-item
            v-for="item in filteredItems"
            :professional-id="professionalId"
            :data="item"
            :key="item.key"
          />
          </tbody>
        </table>
      </template>
    </template>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import fuzzy from 'fuzzy';
import ProcedureItem from './ProcedureItem.vue';

export default {
  mixins: [formMixin],
  components: {
    ProcedureItem,
  },
  props: {
    professionalId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      search: '',
      items: [],
    };
  },
  async mounted() {
    this.loading = true;

    await this.loadProcedures();
    await this.load();

    this.loading = false;
  },
  computed: {
    getSelected() {
      if (!this.items || this.items.length === 0) {
        return false;
      }

      const selected = this.items
        .filter(item => item.selected);

      if (selected.length === 0) {
        return false;
      }

      if (selected.length === this.items.length) {
        return true;
      }

      return null;
    },
    filteredItems() {
      let { items } = this;
      if (this.search.trim()) {
        const results = fuzzy.filter(this.search, items, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        items = results.map(({ original }) => original);
      }

      return items;
    },
  },
  methods: {
    loadProcedures() {
      // const types = [
      //   'consultation',
      //   'evaluation',
      //   'exam',
      //   'physiotherapy',
      //   'procedure',
      //   'service',
      //   'session',
      // ];
      const types = [
        'canteen',
        'consultation',
        'daily',
        'evaluation',
        'exam',
        'gas',
        'laboratory',
        'material',
        'medicine',
        'others',
        'physiotherapy',
        'procedure',
        'service',
        'session',
        'surgery',
        'tax',
      ];

      const params = {
        limit: 0,
        active: true,
        types: types.join(','),
      };

      return this.$httpX.get('/procedures', { params })
        .then(({ data }) => {
          this.items = data.items.map(item => ({
            id: item.id,
            name: item.name,
            type: item.type,
            preChange: {
              selected: false,
            },
            selected: false,
            detail: false,
            search: `${item.tuss ? item.tuss.code : ''}${item.name}`,
          }));
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        });
    },
    load() {
      const params = {
        professionalId: this.professionalId,
      };
      return this.$http.get('/professional-procedures', { params })
        .then(({ data }) => {
          data.items.forEach((item) => {
            const found = this.items.find(base => base.id === item.id);
            if (found) {
              found.preChange.selected = true;
              found.selected = true;
            }
          });
          this.items.sort((a, b) => {
            if (a.selected > b.selected) return -1;
            if (a.selected < b.selected) return 1;
            return 0;
          });
        })
        .catch(() => {});
    },
    selectAllItems(e) {
      this.items.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.professional-procedures {
  margin-top: $layout-spacing;
}
</style>
