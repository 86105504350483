<template>
  <div class="insurance-professionals">
    <div class="loading loading-lg" v-if="loading" />
    <template v-else>
      <div class="empty mt-2" v-if="items.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <div class="empty-title h6">
          {{ origin.type === 'plan' ? 'Profissionais' : 'Convênios' }}
        </div>
        <div class="empty-subtitle">
          <slot>
            Nenhum {{ origin.type === 'plan' ? 'profissional' : 'convênio' }} cadastrado.
          </slot>
        </div>
      </div>
      <template v-else>
        <div class="input-group">
          <input type="text" class="form-input" v-model="search"
                 placeholder="Filtrar...">
          <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                  tabindex="-1" v-if="!search.trim()">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
          <button class="btn btn-gray btn-action input-group-btn btn-icon"
                  tabindex="-1" v-else @click="search = ''">
            <fa-icon :icon="['fal', 'times']"/>
          </button>
        </div>
        <table class="table table-striped table-hover">
          <thead>
          <tr>
            <th>
              <label class="form-checkbox">
                <input type="checkbox"
                       @change="selectAllItems"
                       :checked="getSelected"
                       :indeterminate.prop="getSelected === null">
                <i class="form-icon"></i>
              </label>
            </th>
            <th>{{ origin.type === 'plan' ? 'Profissional' : 'Convênio' }}</th>
            <th>Código na operadora</th>
            <th>CNES</th>
            <th style="width: 80px"></th>
          </tr>
          </thead>
          <tbody>
          <plan-professional-insurance
            v-for="item in filteredItems"
            :type="origin.type"
            :data="item"
            :key="item.key"
          />
          </tbody>
        </table>
      </template>
    </template>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import fuzzy from 'fuzzy';
import { mapActions } from 'vuex';
import PlanProfessionalInsurance from './PlanProfessionalInsurance.vue';

export default {
  mixins: [formMixin],
  components: {
    PlanProfessionalInsurance,
  },
  props: {
    origin: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      search: '',
      items: [],
    };
  },
  async mounted() {
    this.loading = true;

    if (this.origin.type === 'plan') {
      await this.loadProfessionals();
    } else {
      await this.loadInsurances();
    }

    await this.load();

    this.loading = false;
  },
  computed: {
    getSelected() {
      if (!this.items || this.items.length === 0) {
        return false;
      }

      const selected = this.items
        .filter(item => item.selected);

      if (selected.length === 0) {
        return false;
      }

      if (selected.length === this.items.length) {
        return true;
      }

      return null;
    },
    filteredItems() {
      let { items } = this;
      if (this.search.trim()) {
        const results = fuzzy.filter(this.search, items, {
          extract: (el) => {
            if (el.name) {
              return this.removeAccentuation(el.name);
            }
            return '';
          },
        });
        items = results.map(({ original }) => original);
      }

      return items;
    },
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          this.items = items.map(item => ({
            key: item.id,
            id: item.id,
            name: item.name,
            insurance: {
              id: this.origin.insurance.id,
              type: this.origin.insurance.type,
              name: this.origin.insurance.name,
              plan: {
                id: this.origin.insurance.plan.id,
                name: this.origin.insurance.plan.name,
              },
            },
            preChange: {
              cnesCode: '',
              professionalCode: '',
              selected: false,
            },
            cnesCode: '',
            professionalCode: '',
            selected: false,
          }));
        })
        .catch(() => {});
    },
    loadInsurances() {
      const params = {
        limit: 0,
        active: true,
      };

      return this.$http.get('/insurance-plans', { params })
        .then(({ data }) => {
          this.items = data.items.map(item => ({
            key: item.plan.id,
            id: this.origin.professional.id,
            name: this.origin.professional.name,
            insurance: {
              id: item.id,
              name: item.customName,
              type: item.type,
              plan: {
                id: item.plan.id,
                name: item.plan.name,
              },
            },
            preChange: {
              cnesCode: '',
              professionalCode: '',
              selected: false,
            },
            cnesCode: '',
            professionalCode: '',
            selected: false,
          }));
        })
        .catch(() => {});
    },
    load() {
      const params = {
        origin: this.origin.type,
        limit: 1000,
      };

      if (this.origin.type === 'plan') {
        params.insuranceId = this.origin.insurance.id;
        params.planId = this.origin.insurance.plan.id;
      } else {
        params.professionalId = this.origin.professional.id;
      }

      return this.$http.get('/professional-insurances', { params })
        .then(({ data }) => {
          data.items.forEach((item) => {
            const found = this.items
              .find(base => base.insurance.id === item.insurance.id
                && base.insurance.plan.id === item.insurance.plan.id
                && base.id === item.id);

            if (found) {
              found.preChange.cnesCode = item.insurance.cnesCode || '';
              found.preChange.professionalCode = item.insurance.professionalCode || '';
              found.preChange.selected = true;
              found.cnesCode = item.insurance.cnesCode || '';
              found.professionalCode = item.insurance.professionalCode || '';
              found.selected = true;
            }
          });
          this.items.sort((a, b) => {
            if (a.selected > b.selected) return -1;
            if (a.selected < b.selected) return 1;
            return 0;
          });
        })
        .catch(() => {});
    },
    selectAllItems(e) {
      this.items.forEach((item) => {
        item.selected = e.target.checked;
      });
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.insurance-professionals {
  margin-top: $layout-spacing;
}
</style>
