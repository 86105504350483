<template>
  <st-side-page class="professional-page">
    <template>
      <div class="loading loading-lg mt-2" v-if="loading" />
      <template v-else>
        <template slot="side">
          <div class="professional-image" :style="bgImage"
               :class="{'show-options': showImageOptions}">
            <div class="professional-image-options">
              <template v-if="bgImage">
                <button class="btn btn-icon btn-error tooltip" data-tooltip="Remover foto"
                        @click="removeImage()">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </template>
              <template v-else>
                <button class="btn btn-icon btn-gray tooltip" data-tooltip="Enviar foto"
                        @click="image.showOptions = true">
                  <fa-icon :icon="['fal', 'upload']"/>
                  <input type="file" name="image" accept="image/*"
                         @change="loadImage">
                </button>
                <button class="btn btn-icon btn-gray tooltip" data-tooltip="Tirar foto"
                        @click="openWebCam" disabled>
                  <fa-icon :icon="['fal', 'camera']"/>
                </button>
              </template>
            </div>
          </div>
          <div class="columns">
            <div class="column col-12 form-group mt-2">
              <button class="btn btn-block btn-primary"
                      @click="saveProfessional"
                      :disabled="saving || hideSave" :class="{loading: saving}">
                Salvar
              </button>
            </div>
            <div class="column col-12 form-group">
              <button class="btn btn-block" @click="$router.back()">
                Voltar
              </button>
            </div>
          </div>
          <ul class="menu">
            <li class="menu-item" v-for="(menu, i) in menus" :key="i">
              <router-link :to="menu.path">{{ menu.label }}</router-link>
            </li>
          </ul>
        </template>
        <div class="card">
          <portal to="page-name">Cadastro de profissional</portal>
          <div class="card-header">
            <div class="form-group float-right status-toggle">
              <label class="form-switch">
                <input type="checkbox" v-model="form.professional.active">
                <i class="form-icon"/> Registro ativo
              </label>
            </div>
            <div class="card-title">
              Cadastro de Profissional
              <span v-if="form.name"> - </span>
              <small class="text-secondary"> {{ form.name }}</small>
            </div>
          </div>
          <div class="card-body">
            <st-tabs ref="professional-tabs" @changed="changeTabs">
              <!-- TAB: MAIN -->
              <st-tab id="professional" name="Dados principais">
                <div class="columns">
                  <div class="column col-3 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.identity.value.$error}">
                    <label for="company-cpf" class="form-label">CPF</label>
                    <input
                      type="text"
                      id="company-cpf"
                      v-model="form.identity.value"
                      @change="loadEntityProfessional"
                      @blur="$v.form.identity.value.$touch()"
                      class="form-input"
                      autocomplete="nope"
                      :disabled="!canChangeIdentity"
                      placeholder="000.000.000-00"
                      v-mask-cpf
                    >
                    <template v-if="$v.form.identity.value.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.identity.value.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-if="!$v.form.identity.value.cpf">CPF inválido</div>
                    </template>
                  </div>
                  <div class="column col-4 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.name.$error}">
                    <label for="company-name" class="form-label">Nome</label>
                    <input type="text" id="company-name" name="company-name"
                           v-model="form.name" class="form-input" :disabled="!!form.id"
                           @blur="$v.form.name.$touch()" @change="loadEntityByNameAndDob"
                           placeholder="Nome" autocomplete="nope">
                    <template v-if="$v.form.name.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.name.required">Campo obrigatório</div>
                    </template>
                  </div>
                  <div class="column col-3 col-sm-12 form-group"
                       :class="{'has-error': $v.form.birthDate.$error}">
                    <label for="person-birth-date" class="form-label">Nascimento</label>
                    <input type="text" id="person-birth-date" name="person-birth-date"
                           v-model="form.birthDate" class="form-input"
                           @change="loadEntityByNameAndDob" :disabled="!!form.id"
                           @blur="$v.form.birthDate.$touch()" key="person-birth-date"
                           placeholder="dd/mm/aaaa" v-mask-date.br>
                    <template v-if="$v.form.birthDate.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.birthDate.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.form.birthDate.date">Data inválida</div>
                    </template>
                  </div>
                  <div class="column col-2 col-sm-12 form-group"
                       :class="{'has-error': $v.form.gender.$error}">
                    <label class="form-label">Sexo</label>
                    <select id="person-gender" name="person-gender"
                            :disabled="!!form.id && !!form.gender"
                            v-model="form.gender" key="person-gender"
                            class="form-select" @change="$v.form.gender.$touch()">
                      <option value="">[Selecione]</option>
                      <option value="female">Feminino</option>
                      <option value="male">Masculino</option>
                    </select>
                    <template v-if="$v.form.gender.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.gender.required">Campo obrigatório</div>
                    </template>
                  </div>
                </div>
                <div class="columns">
                  <div class="column col-3 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.phone.$error}">
                    <label for="phone" class="form-label">Telefone</label>
                    <input type="text" id="phone" name="phone" v-model="form.phone"
                           class="form-input" @blur="$v.form.phone.$touch()" maxlength="14"
                           placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
                    <template v-if="$v.form.phone.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.phone.phone">Telefone inválido</div>
                    </template>
                  </div>
                  <div class="column col-3 col-md-4 col-sm-12 form-group"
                       :class="{'has-error': $v.form.cellphone.$error}">
                    <label for="cellphone" class="form-label">Celular</label>
                    <input type="text" id="cellphone" name="cellphone"
                           v-model="form.cellphone" class="form-input" autocomplete="nope"
                           @blur="$v.form.cellphone.$touch()"
                           placeholder="(00) 0 0000-0000" v-mask-phone.br>
                    <template v-if="$v.form.cellphone.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.cellphone.phone">Telefone inválido</div>
                    </template>
                  </div>
                  <div
                    class="column col-6 col-md-4 col-sm-12 form-group"
                    :class="{'has-error': $v.form.email.$error}"
                  >
                    <label for="email" class="form-label">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      v-model="form.email"
                      class="form-input"
                      @blur="$v.form.email.$touch()"
                      placeholder="nome@email.com"
                      autocomplete="nope"
                      :disabled="!canEdit"
                    >
                    <template v-if="$v.form.email.$error">
                      <div
                        class="form-input-hint"
                        v-if="!$v.form.email.email"
                      >Email inválido</div>
                    </template>
                  </div>
                </div>
                <div class="text-gray">Dados do profissional</div>
                <div class="divider"></div>
                <div class="columns">
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <label class="form-label">Título</label>
                    <select id="professional-title" name="professional-title"
                            v-model="form.professional.title"
                            class="form-select">
                      <option value="">[Selecione]</option>
                      <option value="Sr.">Sr.</option>
                      <option value="Sra.">Sra.</option>
                      <option value="Dr.">Dr.</option>
                      <option value="Dra.">Dra.</option>
                    </select>
                  </div>
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <label class="form-label">Conselho</label>
                    <select id="professional-council" name="professional-council"
                            v-model="form.professional.council.name"
                            class="form-select">
                      <option value="">[Selecione]</option>
                      <option v-for="(value, text) in council.councils"
                              :value="text" :key="text">{{ text }}</option>
                    </select>
                  </div>
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <label class="form-label">UF</label>
                    <select id="professional-council-state"
                            v-model="form.professional.council.state"
                            class="form-select">
                      <option value="">[Selecione]</option>
                      <option v-for="(text, value) in states"
                              :value="value" :key="value">{{ value }}</option>
                    </select>
                  </div>
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <label for="professional-council-record" class="form-label">Registro</label>
                    <input type="text" id="professional-council-record"
                           name="professional-council-record"
                           v-model="form.professional.council.record" class="form-input"
                           autocomplete="nope" placeholder="00000" v-mask="'00000000'">
                  </div>
                  <div class="column col-12 form-group"
                       :class="{'has-error': $v.form.professional.specialties.$error}">
                    <label class="form-label">Especialidades</label>
                    <table class="table">
                      <tbody>
                      <tr class="table-title">
                        <td colspan="4">
                          <div class="form-group">
                            <select id="professional-specialties"
                                    v-model="specialty" @change="addSpecialty"
                                    class="form-select"
                                    @blur="$v.form.professional.specialties.$touch()">
                              <option value="">[Selecione]</option>
                              <option v-for="(item, i) in specialtiesAvailable"
                                      :value="item.code" :key="i">{{ item.name }}</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-body"
                          v-for="(item, i) in form.professional.specialties" :key="i">
                        <td style="max-width:40px">{{ item.code }}</td>
                        <td>{{ item.name }}</td>
                        <td style="max-width:45px">
                          <input type="text"
                                 class="form-input input-sm text-center"
                                 placeholder="RQE"
                                 v-model="item.rqe"
                                 v-mask="'000000'"
                          >
                        </td>
                        <td class="text-right" style="width:50px">
                          <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                                  data-tooltip="Excluir" @click="deleteSpecialty(i)">
                            <fa-icon :icon="['fal', 'times']"/>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                    <template v-if="$v.form.professional.specialties.$error">
                      <div class="form-input-hint"
                           v-if="!$v.form.professional.specialties.required">
                        Campo obrigatório
                      </div>
                    </template>
                  </div>
                  <div class="column col-12 form-group">
                    <label class="form-label">Grau de participação</label>
                    <table class="table">
                      <tbody>
                      <tr class="table-title">
                        <td colspan="3">
                          <div class="form-group">
                            <select id="professional-degrees-participation"
                                    v-model="degreeParticipation"
                                    @change="addDegreeParticipation"
                                    class="form-select">
                              <option value="">[Selecione]</option>
                              <option v-for="(item, i) in degreesParticipationAvailable"
                                      :value="item.id" :key="i">{{ item.name }}</option>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr class="table-body"
                          v-for="(item, i) in form.professional.degreesParticipation" :key="i">
                        <td>{{ item.name }}</td>
                        <td class="text-right">
                          <button class="btn btn-sm btn-action btn-icon btn-error tooltip"
                                  data-tooltip="Excluir" @click="deleteDegreeParticipation(i)">
                            <fa-icon :icon="['fal', 'times']"/>
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="text-gray mt-2">Dados do usuário</div>
                <div class="divider"></div>
                <div class="columns">
                  <div class="column col-6 mb-2">
                    <label class="form-label">Grupo de permissões</label>
                    <select
                      id="user-permission-group"
                      class="form-select"
                      v-model="form.professional.permissionGroup"
                      :disabled="!canEdit && !!form.professional.permissionGroup"
                    >
                      <option value="">[Selecione]</option>
                      <option
                        v-for="group in permissionGroups"
                        :value="group.id"
                        :key="group.id"
                      >
                        {{ group.name }}
                        <span v-if="group.default"> (Padrão)</span>
                      </option>
                    </select>
                  </div>

                  <!-- USER PASSWORD -->
                  <div class="column col-12 form-group" v-if="!userHasPassword">
                    <div class="columns">
                      <div
                        class="column col-3 col-sm-12 form-group"
                        :class="{'has-error': $v.form.professional.password.$error}"
                      >
                        <label for="password" class="form-label">Senha</label>
                        <input
                          type="password"
                          class="form-input"
                          id="password"
                          v-model="form.professional.password"
                          @change="$v.form.professional.password.$touch()"
                          placeholder="senha"
                        >
                        <template v-if="$v.form.professional.password.$error">
                          <p
                            class="form-input-hint"
                            v-if="!$v.form.professional.password.required"
                          >Campo obrigatório</p>
                          <p
                            class="form-input-hint"
                            v-if="!$v.form.professional.password.minLength"
                          >A senha deve conter no mínimo 6 caracteres</p>
                        </template>
                      </div>
                      <div
                        class="column col-3 col-sm-12 form-group"
                        :class="{'has-error': $v.form.professional.passwordConfirmation.$error}"
                      >
                        <label
                          for="password-confirmation"
                          class="form-label"
                        >Confirme a senha</label>
                        <input
                          type="password"
                          class="form-input"
                          id="password-confirmation"
                          v-model="form.professional.passwordConfirmation"
                          @change="$v.form.professional.passwordConfirmation.$touch()"
                          placeholder="senha"
                        >
                        <template v-if="$v.form.professional.passwordConfirmation.$error">
                          <p
                            class="form-input-hint"
                            v-if="!$v.form.professional.passwordConfirmation.required"
                          >Campo obrigatório</p>
                          <p
                            class="form-input-hint"
                            v-else-if="!$v.form.professional.passwordConfirmation.sameAsPass"
                          >Senhas não conferem</p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <!-- USER PASSWORD - END -->
                </div>

                <div class="text-gray mt-2">Dados do endereço</div>
                <div class="divider"></div>
                <div class="columns">
                  <div class="column col-3 col-md-6 col-sm-12 form-group"
                       :class="{'has-error': $v.address.postalCode.$error}">
                    <label for="address-cep" class="form-label">CEP</label>
                    <div class="input-group">
                      <input type="text" id="address-cep" name="address-cep"
                             class="form-input" v-model="address.postalCode"
                             @blur="$v.address.postalCode.$touch()" placeholder="00.000-000"
                             @change="loadAddress" v-mask-cep autocomplete="nope">
                      <button class="btn btn-neutral btn-icon btn-action input-group-btn"
                              :disabled="loadingAddress" :class="{loading: loadingAddress}">
                        <fa-icon :icon="['fal', 'search']"/>
                      </button>
                    </div>
                    <template v-if="$v.address.postalCode.$error">
                      <div class="form-input-hint"
                           v-if="!$v.address.postalCode.required">Campo obrigatório</div>
                      <div class="form-input-hint"
                           v-else-if="!$v.address.postalCode.cep">CEP inválido</div>
                    </template>
                  </div>
                  <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
                  <div class="column col-9 col-md-12 col-sm-12 form-group">
                    <label for="address-addressLine1" class="form-label">Logradouro</label>
                    <input type="text" id="address-addressLine1" name="address-addressLine1"
                           class="form-input" v-model="address.addressLine1"
                           placeholder="Avenida Brasil, 1000" autocomplete="nope">
                  </div>
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <label for="address-addressLine2" class="form-label">Complemento</label>
                    <input type="text" id="address-addressLine2" name="address-addressLine2"
                           class="form-input" v-model="address.addressLine2"
                           placeholder="casa, sobrado, loja..." autocomplete="nope">
                  </div>
                  <div class="column col-5 col-md-6 col-sm-12 form-group">
                    <label for="address-neighborhood" class="form-label">Bairro</label>
                    <input type="text" id="address-neighborhood" name="address-neighborhood"
                           placeholder="Bairro" v-model="address.neighborhood"
                           class="form-input" autocomplete="nope">
                  </div>
                  <div class="column col-4 col-md-6 col-sm-12 form-group">
                    <label for="address-city" class="form-label">Cidade</label>
                    <input type="text" id="address-city" name="address-city"
                           class="form-input" v-model="address.city"
                           placeholder="Cidade" autocomplete="nope">
                  </div>
                  <div class="column col-3 col-md-6 col-sm-12 form-group">
                    <label class="form-label">Estado</label>
                    <select id="address-state" class="form-select" v-model="address.state">
                      <option value="">[Selecione]</option>
                      <option v-for="(text, value) in states"
                              :value="value" :key="value">{{ value }}</option>
                    </select>
                  </div>
                </div>
                <div class="text-gray">Notas</div>
                <div class="columns">
                  <div class="column col-12 form-group">
                    <label class="form-label">Observações</label>
                    <textarea id="notes" class="form-input" rows="4"
                              v-model="form.notes"/>
                  </div>
                </div>
              </st-tab>

              <!-- TAB: PRINT SETTINGS -->
              <st-tab id="print-settings" name="Impressos">
                <print-settings :professional-id="form.id" ref="printSettings">
                  <div class="text-primary text-bold">Assinatura</div>
                  <div class="columns">
                    <div class="column col-5 form-group school">
                      <label class="form-label">Assinatura personalizada</label>
                      <st-editor id="professional-signature"
                                 v-model="form.professional.signature"></st-editor>
                    </div>
                    <div class="column col-6 form-group">
                      <label class="form-label">Imagem da assinatura</label>
                      <div v-if="bgSignatureImage">
                        <div class="signature-image" :style="bgSignatureImage">
                          <div class="signature-image-options">
                            <button class="btn btn-icon btn-error tooltip"
                                    data-tooltip="Remover assinatura"
                                    @click="removeSignatureImage()">
                              <fa-icon :icon="['fal', 'times']"/>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <input type="file" name="image" accept="image/*"
                               class="mt-2" @change="loadSignatureImage">
                      </div>
                    </div>
                  </div>
                </print-settings>
              </st-tab>

              <!-- TAB: OTHERS -->
              <st-tab id="others" name="Outros">
                <div class="columns">
                  <div class="column col-12 form-group">
                    <label class="form-label">Código global</label>
                    <input type="text"
                           readonly
                           class="form-input"
                           v-model="form.identityId"
                    />
                  </div>
                  <div class="column col-12 col-sm-12 form-group about">
                    <label class="form-label">Sobre mim</label>
                    <st-editor id="professional-about"
                               v-model="form.professional.about"></st-editor>
                  </div>
                  <div class="column col-12 col-sm-12 form-group school">
                    <label class="form-label">Formação</label>
                    <st-editor id="professional-school"
                               v-model="form.professional.school"></st-editor>
                  </div>
                  <div class="column col-12 col-sm-12 form-group experiences">
                    <label class="form-label">Experiências</label>
                    <st-editor id="professional-experiences"
                               v-model="form.professional.experiences"></st-editor>
                  </div>
                  <div class="column col-7 col-sm-12 form-group">
                    <label for="professional-diseases" class="form-label">Doenças tratadas</label>
                    <div class="input-group">
                      <input type="text" v-model="disease" id="professional-diseases"
                             ref="professionalDiseases"
                             class="form-input" autocomplete="nope" @keydown.enter="addDisease">
                      <button @click="addDisease"
                              class="btn btn-neutral btn-action input-group-btn btn-icon">
                        <fa-icon :icon="['fal', 'plus']"/>
                      </button>
                    </div>
                    <div class="chip bg-secondary mt-2"
                         v-for="(item, i) in form.professional.diseases" :key="i">
                      <span>{{ item }}</span>
                      <button class="btn btn-clear" aria-label="Close"
                              @click="deleteDisease(i)"/>
                    </div>
                  </div>
                  <div class="column col-5 col-sm-12 form-group">
                    <label class="form-label">
                      Visualizar todas as agendas
                    </label>
                    <select id="can-view-schedule" :disabled="!form.id"
                            class="form-select" v-model="canViewSchedule"
                            @change="saveScheduleSettings">
                      <option value="false">Não</option>
                      <option value="true">Sim</option>
                    </select>
                  </div>
                </div>
              </st-tab>

              <!-- TAB: INSURANCES -->
              <st-tab id="insurances" name="Convênios">
                <plan-professionals
                  v-if="form.id && selectedTab !== null"
                  :origin="getOrigin"
                />
                <div class="empty mt-2" v-else>
                  <div class="empty-icon">
                    <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
                  </div>
                  <p class="empty-title h5">Convênios</p>
                  <p class="empty-subtitle">
                    Você precisa salvar para ter acesso a lista de convênios.
                  </p>
                </div>
              </st-tab>

              <!-- TAB: PROCEDURES -->
              <st-tab id="procedures" name="Procedimentos">
                <procedures
                  v-if="form.id"
                  :professional-id="form.id"
                />
              </st-tab>

              <!-- TAB: TRANSFER VALUES -->
              <st-tab id="transfer-values" name="Valores de repasse">
                <transfer-values
                  v-if="form.id && selectedTab === 'transfer-values'"
                  :professional-id="form.id"
                />
              </st-tab>
            </st-tabs>
          </div>
        </div>
      </template>
    </template>
  </st-side-page>
</template>

<script>
import { mapGetters } from 'vuex';
import entityMixin from 'src/mixins/entity';
import { required } from 'vuelidate/src/validators';
import { cpf, cep } from 'src/data/validators';
import * as council from 'src/data/council-types';
import states from 'src/data/states';
import { minLength, sameAs } from 'vuelidate/lib/validators';
import { degreesParticipation } from '@/data/degrees-participation';
import PlanProfessionals from '../../insurance/components/modals/PlanProfessionals.vue';
import Procedures from './Procedures.vue';
import TransferValues from './TransferValues.vue';
import PrintSettings from './PrintSettings.vue';

export default {
  mixins: [entityMixin],
  components: {
    PlanProfessionals,
    Procedures,
    TransferValues,
    PrintSettings,
  },
  data() {
    return {
      includesUserData: true, // Do not remove this field
      type: 'professional',
      path: '/professionals',
      selectedTab: 'professional',
      hideSave: false,
      council,
      states,
      disease: '',
      specialties: [],
      degreesParticipation,
      permissionGroups: [],
      menus: [],
      specialty: '',
      degreeParticipation: '',
      form: {},
      image: this.imageFormBlank(),
      signatureImage: this.imageFormBlank(),
      canViewSchedule: false,
    };
  },
  created() {
    this.loading = true;
    this.form = this.blankForm();
  },
  validations() {
    return this.entityValidationRules((rules) => {
      const localRules = rules;
      localRules.address = {
        postalCode: { cep },
      };
      localRules.form.identity = {
        value: { required, cpf },
      };
      localRules.nameAndDob = ['form.name', 'form.birthDate'];

      localRules.form.professional = {
        specialties: {
          required,
        },
        password: {},
        passwordConfirmation: {},
      };

      if (!this.userHasPassword) {
        localRules.form.professional.password = {
          required,
          minLength: minLength(6),
        };

        localRules.form.professional.passwordConfirmation = {
          required,
          sameAsPass: sameAs('password'),
        };
      }

      return localRules;
    });
  },
  async mounted() {
    this.loading = true;

    this.isNew = /create$/.test(this.$route.path);
    this.form.type = 'person';
    await this.loadSpecialties();
    await this.loadPermissionGroups();

    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      await this.load(this.form.id, this.path)
        .then(() => {
          this.loadScheduleSettings();
        });
    }
    this.loading = false;
  },
  computed: {
    ...mapGetters(['isUserRestricted']),
    specialtiesAvailable() {
      return this.specialties
        .filter(item => this.form.professional.specialties.every(s => s.code !== item.code));
    },
    degreesParticipationAvailable() {
      const degrees = this.getDegreesParticipation();
      return degrees.filter(item => this
        .form.professional.degreesParticipation.every(s => s.id !== item.id));
    },
    bgImage() {
      let backgroundImage = null;

      if (this.image.preview) {
        backgroundImage = `url('${this.image.preview}')`;
      } else if (this.form.imageUrl) {
        backgroundImage = `url('${this.form.imageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    bgSignatureImage() {
      let backgroundImage = null;

      if (this.signatureImage.preview) {
        backgroundImage = `url('${this.signatureImage.preview}')`;
      } else if (this.form.professional.signatureImageUrl) {
        backgroundImage = `url('${this.form.professional.signatureImageUrl}?w=200&h=200')`;
      }
      return backgroundImage ? { backgroundImage } : null;
    },
    showImageOptions() {
      if (this.image.showOptions) {
        return true;
      }
      return this.bgImage === null;
    },
    getOrigin() {
      return {
        type: 'professional',
        professional: {
          id: this.form.id,
          name: this.form.name,
        },
      };
    },
    canEdit() {
      return !this.isUserRestricted(this.form.id);
    },
    userHasPassword() {
      return this.form.id && this.form.user.usernames.length > 0;
    },
  },
  methods: {
    changeTabs({ tab }) {
      this.selectedTab = tab.hash;
      this.hideSave = ['insurances', 'procedures', 'transfer-values'].includes(tab.id);
    },
    addDisease() {
      this.form.professional.diseases.push(this.disease);
      this.disease = '';
      this.$nextTick(() => this.$refs.professionalDiseases.focus());
    },
    deleteDisease(i) {
      this.form.professional.diseases.splice(i, 1);
    },
    loadSignatureImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.signatureImage.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.signatureImage.preview = e.target.result;
        };
        reader.readAsDataURL(this.signatureImage.file);
      } else {
        this.signatureImage.file = null;
      }
    },
    removeSignatureImage() {
      this.signatureImage = this.imageFormBlank();
      this.form.professional.signatureImageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/signature-image`)
        .catch(() => {});
    },
    loadImage(e) {
      e.preventDefault();
      if (e.target.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        this.image.file = e.target.files[0];
        const reader = new FileReader();
        // eslint-disable-next-line no-shadow
        reader.onload = (e) => {
          this.image.preview = e.target.result;
          this.image.showOptions = false;
        };
        reader.readAsDataURL(this.image.file);
      } else {
        this.image.file = null;
        this.image.showOptions = false;
      }
    },
    removeImage() {
      this.image = this.imageFormBlank();
      this.form.imageUrl = null;

      if (!this.form.id) {
        return;
      }
      this.$http
        .delete(`/entities/${this.form.id}/images`)
        .catch(() => {});
    },
    openWebCam() {
      this.image.showWebCam = true;
      this.image.showOptions = true;
      this.$nextTick(() => this.$refs.btnTakeSnapshot.focus());
    },
    loadEntityProfessional() {
      this.loadEntityByIdentity(this.path);
    },
    loadEntityByNameAndDob() {
      if (this.$v.nameAndDob.$invalid) return;
      const params = {
        name: this.form.name,
        birthDate: this.dateToApi(this.form.birthDate),
      };
      this.loadEntity({ params }, this.path);
    },
    getDegreesParticipation() {
      return Object.entries(this.degreesParticipation)
        .map(([keys, values]) => ({
          id: keys,
          name: values,
        }));
    },
    addSpecialty() {
      if (!this.specialty) {
        return;
      }
      const specialty = this.specialties.find(item => item.code === this.specialty);
      if (specialty) {
        this.form.professional.specialties.push({
          code: specialty.code,
          name: specialty.name,
          rqe: '',
        });
      }
      this.specialty = '';
    },
    addDegreeParticipation() {
      if (!this.degreeParticipation) {
        return;
      }

      const degrees = this.getDegreesParticipation();
      const degreeParticipation = degrees.find(item => item.id === this.degreeParticipation);

      if (degreeParticipation) {
        this.form.professional.degreesParticipation.push({
          id: degreeParticipation.id,
          name: degreeParticipation.name,
        });
      }
      this.degreeParticipation = '';
    },
    deleteSpecialty(i) {
      this.form.professional.specialties.splice(i, 1);
    },
    deleteDegreeParticipation(i) {
      this.form.professional.degreesParticipation.splice(i, 1);
    },
    saveProfessional() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.form.type = 'person';
      this.address.type = 'business';

      this.save(this.path)
        .then((data) => {
          if (/create$/.test(this.$route.path)) {
            this.$router.replace(`/clinical${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          if (this.image.file) {
            const formData = new FormData();
            formData.append('file', this.image.file);
            this.$http.post(`/entities/${data.id}/images`, formData)
              .then(() => {
                this.image.file = null;
              })
              .catch(() => {});
          }
          if (this.signatureImage.file) {
            const formData = new FormData();
            formData.append('file', this.signatureImage.file);
            this.$http.post(`/entities/${data.id}/signature-image`, formData)
              .then(() => {
                this.signatureImage.file = null;
              })
              .catch(() => {});
          }
          this.$refs.printSettings.save();
          this.$toast.show('Registro salvo');
        })
        .catch((e) => {
          if (e.response
            && e.response.data
            && e.response.data.message) {
            this.$toast.show(e.response.data.message, { type: 'error', timeout: 5000 });
          }
        })
        .then(() => {
          this.saving = false;
        });
    },
    loadSpecialties() {
      const params = {
        limit: 0,
        isSpecialty: true,
      };
      return this.$http.get('/services/cbos', { params })
        .then(({ data }) => {
          this.specialties = data.items;
        })
        .catch(() => {});
    },
    loadPermissionGroups() {
      const params = {
        limit: 0,
      };
      return this.$http.get('/groups', { params })
        .then(({ data }) => {
          this.permissionGroups = data.items;
        })
        .catch(() => {});
    },
    imageFormBlank() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    loadScheduleSettings() {
      if (!this.form.id) {
        return false;
      }

      return this.$http.get(`/schedule-settings/${this.form.id}`)
        .then(({ data }) => {
          this.canViewSchedule = data;
        })
        .catch(() => {});
    },
    saveScheduleSettings() {
      if (!this.form.id) {
        return;
      }

      const data = {
        entityId: this.form.id,
        canViewSchedule: this.canViewSchedule === 'true',
      };

      this.$http.post('/schedule-settings', data)
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .professional-page {
    &.side-page .side-page-side {
      flex: 0 0 10.1rem;
      dd {
        margin-top: 0;
      }
    }
    .professional-image {
      margin-top: $layout-spacing;
      background: $gray-color-light center no-repeat;
      background-size: cover;
      border-radius: $border-radius;
      height: 9rem;
      width: 9rem;
      &:not(.show-options):hover {
        .professional-image-options {
          opacity: 1;
          background-color: rgba($gray-color-light, .97);
        }
      }
      &.show-options {
        .professional-image-options {
          opacity: 1;
        }
      }
    }
    .professional-image-options {
      align-items: center;
      background-color: $gray-color-light;
      border-radius: $border-radius;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: all .3s ease;
      .btn {
        margin: calc($layout-spacing / 2);
        position: relative;
        input {
          bottom: 0;
          display: block;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: $zindex-0;
        }
      }
    }
    .insurance-branch-item {
      align-items: center;
      background-color: #eee;
      border: $border-width solid $border-color;
      display: flex;
      flex-direction: row;
      padding: $layout-spacing;
    }
    .panel {
      background-color: #fff;
    }
    .table {
      .table-title {
        background-color: $gray-color-light;
      }
      .table-body {
        background-color: lighten($gray-color-light, 4%);
      }
    }
    .about {
      .document-editor {
        .document-editor-body {
          .ck.ck-content.ck-editor__editable {
            min-height: 5rem;
          }
        }
      }
    }
    .school {
      .document-editor {
        .document-editor-body {
          .ck.ck-content.ck-editor__editable {
            min-height: 8rem;
          }
        }
      }
    }
    .experiences {
      .document-editor {
        .document-editor-body {
          .ck.ck-content.ck-editor__editable {
            min-height: 5rem;
          }
        }
      }
    }
    .signature-image {
      background-size: cover;
      border-radius: $border-radius;
      height: 5rem;
      width: 7rem;
      &:not(.show-options):hover {
        .signature-image-options {
          opacity: 1;
          background-color: rgba($gray-color-light, .97);
        }
      }
      &.show-options {
        .signature-image-options {
          opacity: 1;
        }
      }
    }
    .signature-image-options {
      align-items: center;
      background-color: $gray-color-light;
      border-radius: $border-radius;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      transition: all .3s ease;
      .btn {
        margin: calc($layout-spacing / 2);
        position: relative;
        input {
          bottom: 0;
          display: block;
          left: 0;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: $zindex-0;
        }
      }
    }
  }
</style>
