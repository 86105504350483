<template>
  <tr>
    <td style="width: 50px">
      <label class="form-checkbox">
        <input type="checkbox"
               v-model="item.selected">
        <i class="form-icon"></i>
      </label>
    </td>
    <td>{{ item.name }}</td>
    <td style="width: 80px">
      <button class="btn btn-icon btn-action mr-1" @click="cancel"
              :class="change ? 'btn-gray' : 'btn-neutral'"
              :disabled="!change">
        <fa-icon :icon="['fal', 'times']" />
      </button>
      <button class="btn btn-icon btn-action"
              :class="classes"
              :disabled="saving || !change"
              @click="save">
        <fa-icon :icon="['fal', 'save']" />
      </button>
    </td>
  </tr>
</template>

<script>
import formMixin from '@/mixins/form';

export default {
  mixins: [formMixin],
  props: {
    data: {
      type: Object,
    },
    professionalId: {
      type: String,
    },
  },
  data() {
    return {
      change: false,
      saving: false,
      item: this.blankItem,
    };
  },
  watch: {
    item: {
      handler() {
        this.changeValues();
      },
      deep: true,
    },
  },
  mounted() {
    this.item = this.data;
  },
  computed: {
    classes() {
      const items = [this.change ? 'btn-primary' : 'btn-neutral'];

      if (this.saving) {
        items.push('loading');
      }

      return items;
    },
  },
  methods: {
    changeValues() {
      this.change = this.item.preChange.selected !== this.item.selected;
    },
    setInitialValues() {
      this.item.selected = this.item.preChange.selected;
    },
    cancel() {
      this.change = false;
      this.setInitialValues();
    },
    save() {
      if (this.saving) {
        return null;
      }

      this.saving = true;

      const data = this.clone(this.item);

      return this.$http
        .post(`/professionals/${this.professionalId}/procedures`, data)
        .then(() => {
          this.item.preChange.selected = data.selected;
          this.change = false;
          this.$toast.show('Salvo com sucesso!');
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    blankItem() {
      return {
        id: '',
        name: '',
        type: '',
        preChange: {
          selected: false,
        },
        selected: false,
      };
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/variables";

.professional-insurance-item {
}
</style>
