<template>
  <div class="professional-transfer-values">
    <div class="columns mb-2">
      <div class="column col-4">
        <label class="form-label">Convênios</label>
        <select class="form-select" id="filter-insurance"
                v-model="filters.insurancePlanId" @change="load">
          <option value="">[Selecione um convênios]</option>
          <option v-for="(item, i) in insurances"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-4">
        <label class="form-label">Procedimentos</label>
        <select class="form-select" id="filter-insurance"
                v-model="filters.expenseId" @change="load">
          <option value="">[Selecione um procedimento]</option>
          <option v-for="(item, i) in expenses"
                  :value="item.id" :key="i">{{ item.name }}</option>
        </select>
      </div>
      <div class="column col-4">
        <label class="form-label">Procedimento</label>
        <div class="input-group">
          <input type="text" class="form-input" v-model="filters.search"
                 placeholder="Digite o código ou nome do procedimento">
          <button class="btn btn-neutral btn-action input-group-btn btn-icon"
                  tabindex="-1" v-if="!filters.search.trim()">
            <fa-icon :icon="['fal', 'search']"/>
          </button>
          <button class="btn btn-gray btn-action input-group-btn btn-icon"
                  tabindex="-1" v-else @click="filters.search = ''">
            <fa-icon :icon="['fal', 'times']"/>
          </button>
        </div>
      </div>
    </div>
    <div class="loading loading-lg mt-2" v-if="loading" />
    <template v-else>
      <div class="empty mt-2" v-if="procedureItems.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <div class="empty-title h6">Procedimentos</div>
        <div class="empty-subtitle">
          <slot>Selecione o convênio ou procedimento</slot>
        </div>
      </div>
      <div class="empty mt-2" v-else-if="filteredProcedures.length === 0">
        <div class="empty-icon">
          <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
        </div>
        <p class="empty-title h5">Procedimentos</p>
        <p class="empty-subtitle">
          Não foi encontrado nenhum procedimento com estes filtros
        </p>
      </div>
      <template v-else>
        <div class="procedure-group">
          <div class="columns">
            <div class="column col-auto">#</div>
            <div class="column">Procedimentos</div>
            <div class="column col-3">Convênio</div>
            <div class="column col-2 text-right">Repasse</div>
          </div>
        </div>
        <transfer-value-item
          class="procedure-list"
          v-for="(item, i) in filteredProcedures"
          :professional-id="professionalId"
          :data="item"
          :professionals="professionals"
          :key="item.id + i"
          :idx="i"
        />
      </template>
    </template>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import { customName } from 'src/helpers/insurance';
import fuzzy from 'fuzzy';
import { mapActions } from 'vuex';
import TransferValueItem from './TransferValueItem.vue';

export default {
  mixins: [formMixin],
  components: {
    TransferValueItem,
  },
  props: {
    professionalId: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      insurances: [],
      expenses: [],
      procedureItems: [],
      professionals: [],
      filters: {
        insurancePlanId: '',
        expenseId: '',
        search: '',
      },
    };
  },
  async mounted() {
    this.loadProfessionals();
    this.loadInsurances();
    this.loadProcedures();
  },
  computed: {
    filteredProcedures() {
      let { procedureItems } = this;

      if (this.filters.search.trim()) {
        const results = fuzzy.filter(this.filters.search, procedureItems, {
          extract: (el) => {
            if (el.search) {
              return this.removeAccentuation(el.search);
            }
            return '';
          },
        });
        procedureItems = results.map(({ original }) => original);
      }

      return procedureItems;
    },
  },
  methods: {
    ...mapActions({
      apiFetchProfessionals: 'apiFetchProfessionals',
    }),
    loadProfessionals() {
      return this.apiFetchProfessionals()
        .then((items) => {
          items.forEach((item) => {
            item.professional.specialties.forEach((specialty) => {
              this.professionals.push({
                key: `${item.id}-${specialty.code}`,
                id: item.id,
                name: item.name,
                council: item.professional.councils[0],
                specialty,
              });
            });
          });
        })
        .catch(() => {});
    },
    load() {
      this.procedureItems = [];

      if (!this.filters.insurancePlanId && !this.filters.expenseId) {
        return null;
      }

      this.loading = true;

      const params = {
        origin: 'transfer',
        limit: 1000,
      };

      if (this.filters.insurancePlanId) {
        params.planId = this.filters.insurancePlanId;
      }

      if (this.filters.expenseId) {
        params.expenseId = this.filters.expenseId;
      }

      return this.$http
        .get(`/professionals/${this.professionalId}/expense-prices`, { params })
        .then(({ data }) => {
          this.procedureItems = data.items.map((item) => {
            item.search = `${item.code}${item.expense.name}`;
            return item;
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadInsurances() {
      const params = {
        limit: 1000,
        professionalId: this.professionalId,
      };
      return this.$http.get('/professional-insurances', { params })
        .then(({ data }) => {
          this.insurances = data.items.map(item => ({
            id: item.insurance.plan.id,
            name: customName(item.insurance),
          }));
        })
        .catch(() => {});
    },
    loadProcedures() {
      const params = {
        professionalId: this.professionalId,
      };
      return this.$http.get('/professional-procedures', { params })
        .then(({ data }) => {
          this.expenses = data.items;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/_variables.scss';
@import 'src/assets/scss/colors';

.professional-transfer-values {
  margin-top: $layout-spacing;
  .procedure-group {
    background-color: $blue-color-100;
    font-weight: bold;
    padding: $layout-spacing $layout-spacing-lg;
  }
  .procedure-list {
    border-top: $border-width solid $border-color;
    padding: $layout-spacing $layout-spacing-lg;
  }
}
</style>
