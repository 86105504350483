<template>
  <div class="professional-print-settings">
    <div class="form-group mt-2">
      <label class="form-switch">
        <input type="checkbox" v-model="form.custom">
        <i class="form-icon"></i> Utilizar configurações personalizadas
      </label>
    </div>

    <template v-if="form.custom">
      <div class="columns" style="max-width:30rem">
        <div class="column form-group">
          <label class="form-switch">
            <input type="checkbox" v-model="form.showHeader">
            <i class="form-icon"></i>Imprime cabeçalho
          </label>
        </div>
        <div class="column form-group">
          <label class="form-switch">
            <input type="checkbox" v-model="form.showFooter">
            <i class="form-icon"></i>Imprime rodapé
          </label>
        </div>
      </div>

      <div class="text-primary text-bold">Margens</div>
      <div class="columns mb-2">
        <div class="column form-group col-3 col-md-12 col-sm-12">
          <label for="margin-top" class="form-label">Superior</label>
          <div class="input-group">
            <input type="number" id="margin-top" v-mask="'0000'"
                   v-model="form.margins.top"
                   class="form-input" placeholder="0">
            <span class="input-group-addon text-gray">mm</span>
          </div>
        </div>
        <div class="column form-group col-3 col-md-12 col-sm-12">
          <label for="margin-bottom" class="form-label">Inferior</label>
          <div class="input-group">
            <input type="number" id="margin-bottom" v-mask="'0000'"
                   v-model="form.margins.bottom"
                   class="form-input" placeholder="0">
            <span class="input-group-addon text-gray">mm</span>
          </div>
        </div>
        <div class="column form-group col-3 col-md-12 col-sm-12">
          <label for="margin-left" class="form-label">Esquerda</label>
          <div class="input-group">
            <input type="number" id="margin-left" v-mask="'0000'"
                   v-model="form.margins.left"
                   class="form-input" placeholder="0">
            <span class="input-group-addon text-gray">mm</span>
          </div>
        </div>
        <div class="column form-group col-3 col-md-12 col-sm-12">
          <label for="margin-right" class="form-label">Direita</label>
          <div class="input-group">
            <input type="number" id="margin-right" v-mask="'0000'"
                   v-model="form.margins.right"
                   class="form-input" placeholder="0">
            <span class="input-group-addon text-gray">mm</span>
          </div>
        </div>
      </div>
    </template>

    <slot></slot>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import { mergeFrom } from '@/helpers/object';

export default {
  mixins: [formMixin],
  props: {
    professionalId: {
      type: String,
    },
  },
  data() {
    return {
      form: this.blankForm(),
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    apiUrl() {
      return `/professionals/${this.professionalId}/print-settings`;
    },
  },
  methods: {
    async load() {
      if (this.professionalId) {
        const { data } = await this.$http.get(this.apiUrl);
        this.form = mergeFrom(this.blankForm(), data);
      }
    },
    async save() {
      const postData = this.clone(this.form);
      await this.$http.post(this.apiUrl, postData);
    },
    blankForm() {
      return {
        custom: false,
        showHeader: true,
        showFooter: true,
        margins: {
          top: 60,
          bottom: 60,
          left: 40,
          right: 40,
        },
      };
    },
  },
};
</script>
